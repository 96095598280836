import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../../src/pages/Home';
import Links from '../../src/pages/Links';
import Localizacao from '../../src/pages/Localizacao';
import Politica from '../../src/pages/Politica';
import Contatos from '../../src/pages/Contatos';

// Definição do componente funcional com anotações de tipo
const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/links" element={<Links />} />
        <Route path="/localizacao" element={<Localizacao />} />
        <Route path="/politica" element={<Politica />} />
        <Route path="/contatos" element={<Contatos />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;


