import React from 'react';
import { Footer as FlowbiteFooter } from 'flowbite-react';

export function Footer() {
  // Estilos para o rodapé
  const footerStyles: React.CSSProperties = {
    backgroundColor: '#1E90FF', // Fundo azul
    color: 'white', // Texto branco
    textAlign: 'center', // Alinhamento central
    borderTop: '5px solid white',
    
    
  };

  // Estilos para o grupo de links
  const linkGroupStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px', // Espaçamento entre os links
    backgroundColor: '#1E90FF', // Cor de fundo do grupo de links
    height: '100px',
    color: 'white',
    
    alignItems: 'center', // Alinhar links verticalmente
  };

  // Estilos para os links
  const linkStyles: React.CSSProperties = {
    color: 'white', // Cor do texto dos links
    fontWeight: 'bold', // Texto em negrito
    textDecoration: 'none', // Remove sublinhado dos links
    fontSize: '16px', // Tamanho da fonte para melhor legibilidade
  };

  return (
    <FlowbiteFooter style={footerStyles}>
      <div style={linkGroupStyles}>
        <a href="#" style={linkStyles}>LINKS</a>
        <a href="#" style={linkStyles}>POLÍTICA DE PRIVACIDADE</a>
        <a href="#" style={linkStyles}>FALE CONOSCO</a>
      </div>
    </FlowbiteFooter>
  );
}

export default Footer;





