import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../components/Carousel';
import Modal from 'react-bootstrap/Modal';
import Footer from '../components/Footer';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import img1 from '../assets/1.png';
import img2 from '../assets/2.png';
import img3 from '../assets/3.png';
import img4 from '../assets/EMISSÃO DE BOLETOS .png';
import img5 from '../assets/paulo.png';
import img6 from '../assets/12.png';
import video1 from '../video/tt.mp4';

const modalContainerStyles: React.CSSProperties = {
  position: 'relative',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1050,
  overflow: 'hidden',
  marginTop: '5px',
  
};

const modalStyles: React.CSSProperties = {
  position: 'relative',
  padding: '20px',
  maxWidth: '1800px',
  width: '70vw',
  height: '1000px',
  marginBottom: '20px',
  marginTop: '15px',
  borderRadius: '8px',
  color: 'white',
  overflow: 'hidden',
  backgroundColor: 'rgba(30, 144, 255, 0.8)',
  
};

const titleStyles: React.CSSProperties = {
  color: 'white',
  fontSize: '100px',
  paddingLeft: '30%',
};

const imgStyle: React.CSSProperties = {
  width: '1000px',
  marginLeft: '12%',
};

const divPauloStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '800px',
  marginTop: '15px',
  backgroundImage: `url(${img6})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const textCardStyles: React.CSSProperties = {
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'sans-serif',
};

const videoStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
};

export const Home: React.FC = () => {
  return (
    <>
      <Header />
      <Carousel >
        <Carousel.Item>
          <ExampleCarouselImage src={img1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <ExampleCarouselImage src={img2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <ExampleCarouselImage src={img3} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
      <div style={modalContainerStyles}>
        <video style={videoStyles} autoPlay loop muted>
          <source src={video1} type="video/mp4" />
        </video>
        <Modal.Dialog style={{ margin: '0 auto' }}>
          <Modal.Body style={modalStyles}>
            <Modal.Title style={titleStyles}>O Cartório</Modal.Title>
            <p>
              O 1º Ofício de Protesto de Petrópolis/RJ, sob a responsabilidade do Tabelião/Registrador Paulo Roberto Nunes dos Santos, tem como atribuição exclusiva o Protesto de Títulos. Este serviço visa garantir a autenticidade, publicidade, segurança e eficácia dos atos jurídicos. O cartório é responsável por:
            </p>
            <ul>
              <li>Protocolização, intimação e acolhimento da devolução ou aceite do título;</li>
              <li>Recebimento do pagamento do título e de outros documentos da dívida;</li>
              <li>Lavratura e registro do protesto, ou acatamento da desistência do credor;</li>
              <li>Procedimento das averbações, prestação de informações e fornecimento de certidões relacionadas a todos os atos praticados.</li>
            </ul>
            <p>
              O protesto é o ato formal e solene pelo qual se prova a inadimplência e o descumprimento de obrigações originadas em títulos e outros documentos da dívida, conforme a lei 9.492/97.
            </p>
            <img style={imgStyle} src={img4} alt="Emissão de Boletos" />
          </Modal.Body>
          
        </Modal.Dialog>
      </div>
      
      <Footer />
    </>
  );
};

export default Home;















