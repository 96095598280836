import React from 'react';
import '../App.css'; // Importação de estilos globais
import Header from '../components/Header';
import Footer from '../components/Footer';
import Card from 'react-bootstrap/Card';

// Definindo o estilo com o tipo React.CSSProperties
const divStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '500px',
  marginTop: '5px',
  textAlign: 'center',
};

const Links: React.FC = () => {
  return (
    <>
      <Header />
      <div style={divStyle}>
        {['Danger'].map((variant) => (
          <Card
            bg={variant.toLowerCase()}
            key={variant}
            text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
            style={{ width: '44rem' }}
            className="mb-2"
          >
            <Card.Header>
              <h1>Sites/Emails Úteis</h1>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <a href="https://www4.tjrj.jus.br/Portal-Extrajudicial/institucional.aspx" target="_blank" rel="noopener noreferrer">
                  Serviço de Selos - SELEX
                  <br />
                  e-mail: cgjselex@tjrj.jus.br
                  <br />
                  Telefone: (21) 3133-3282 / (21) 3133-1941 / (21) 3133-3523
                  <br />
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Links;


  

  
