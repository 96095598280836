import React from 'react';
import styled from 'styled-components';

// Tipagem das propriedades do componente
interface ExampleCarouselImageProps {
  src: string;
  alt: string;
}

// Estilos para o wrapper da imagem
const ImageWrapper = styled.div`
  width: 100vw; /* 100% da largura da viewport */
  height: 80vh; /* 70% da altura da viewport */
  
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Evita barras de rolagem se a imagem for maior que a tela */
`;

// Estilos para a imagem
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta a imagem para cobrir o contêiner sem distorção */
`;

// Componente funcional com tipagem
const ExampleCarouselImage: React.FC<ExampleCarouselImageProps> = ({ src, alt }) => {
  return (
    <ImageWrapper>
      <Image src={src} alt={alt} />
    </ImageWrapper>
  );
};

export default ExampleCarouselImage;



